const {
  VITE_API_URL,
  VITE_BASE_URL,
  VITE_ENV,
  VITE_COMPANY_LOGO_PATH,
  VITE_COUNTRY_FLAG_PATH,
  VITE_VESSEL_PICTURES_PATH,
  VITE_USER_PICTURES_PATH,
  VITE_HELP_PATH,
  VITE_MAPBOX_API_TOKEN,
  VITE_PUBLIC_UPLOAD_DIRECTORY_PATH,
} = import.meta.env

const appConfig = {
  apiUrl: VITE_API_URL,
  baseUrl: VITE_BASE_URL,
  env: VITE_ENV,
  version: VITE_APP_VERSION,
  logoPath: VITE_COMPANY_LOGO_PATH,
  countryFlagPath: VITE_COUNTRY_FLAG_PATH,
  vesselPicturesPath: VITE_VESSEL_PICTURES_PATH,
  usersPicturePath: VITE_USER_PICTURES_PATH,
  helpPath: VITE_HELP_PATH,
  mapBoxToken: VITE_MAPBOX_API_TOKEN,
  uploadDirectoryPath: VITE_PUBLIC_UPLOAD_DIRECTORY_PATH,
}

export default appConfig
