import axios from 'axios'
import VueAxios from 'vue-axios'
import appConfigs from '../appConfig'
import router from '../router'

import { useAuthStore } from '@/stores/auth'
import { useNotificationStore } from '@/stores/notification'

axios.defaults.baseURL = appConfigs.apiUrl
axios.defaults.withCredentials = false

const retryConfig = {
  retries: 10,
  retryDelay: 400,
}

const retryRequest = async (error, retryCount = 0) => {
  const { config } = error
  if (!config || retryCount >= retryConfig.retries) {
    return Promise.reject(error)
  }

  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(axios(config))
    }, retryConfig.retryDelay)
  }).catch((error) => {
    return retryRequest(error, retryCount + 1)
  })
}

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const status = error.response ? error.response.status : null
    const notification = useNotificationStore()
    const auth = useAuthStore()

    // Try to reconnect if server is unavailable
    if (!status || status === 503) {
      try {
        return await retryRequest(error)
      } catch (retryError) {
        notification.newNotification({
          title: 'Backend API unavailable',
          message: 'Connection lost with server after multiple retries. Please refresh page to try again.',
          type: 'error',
        })
      }
    }

    if (status === 401) {
      notification.newNotification({
        title: 'Unauthorized',
        message: 'Your session expired. Please login again.',
        type: 'error',
      })
    } else if (status === 404) {
      notification.newNotification({
        title: 'Not found',
        message: 'The requested resource was not found.',
        type: 'error',
      })
    }

    if ((!status || status === 401) && router.currentRoute.fullPath !== '/login') {
      setTimeout(() => {
        auth.clear()
        router.push({ name: 'login' })
      }, 800)
    }
    return Promise.reject(error)
  },
)

export { axios, VueAxios }
