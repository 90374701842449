<template>
  <v-responsive>
    <v-layout class="app-container d-flex align-center justify-center flex-column min-h-100">
      <div class="login-card pa-4 pa-lg-10 flex-1-1-100 d-flex flex-column">
        <div class="mt-auto text-center mb-8 mb-lg-16">
          <v-img
            :width="370"
            src="@/assets/logo-trammo-vessel-tracker@2x.png"
            alt="Trammo Vessel Tracker"
            class="mx-auto animated slide-fade-bottom"
          ></v-img>
          <div
            class="text-secondary pr-14 pr-lg-10 text-end pt-2 text-sm animated slide-fade-bottom"
            style="animation-delay: 100ms"
          >
            v{{ version }}
          </div>
        </div>
        <router-view />
      </div>
      <AppFooter />
    </v-layout>
  </v-responsive>
</template>
<script setup>
import appConfig from '@/appConfig'
console.log(appConfig)
const version = ref(appConfig.version)
</script>
<style scoped>
.app-container {
  min-height: 100dvh;
}
.login-card {
  width: 100%;
  max-width: 520px;
  height: auto;
}
</style>
