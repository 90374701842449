<template>
  <v-footer class="bg-transparent text-center">
    <v-row>
      <v-col class="mt-auto py-4 py-lg-10 text-center text-indigo-lighten-3 text-body-2 font-weight-light" cols="12">
        © Trammo - {{ new Date().getFullYear() }} |
        <a href="https://www.trammo.com/privacy-policy" target="_blank" class="text-reset">confidentiality policy</a>
        |
        <a href="https://www.trammo.com/legal-info" target="_blank" class="text-reset">legal notice</a> - v{{ version }}
      </v-col>
    </v-row>
  </v-footer>
</template>

<script setup>
import appConfig from '@/appConfig'

const version = ref(appConfig.version)
</script>
